<template>
	<el-main>
		<div class="searchdiv">
			<div class="searchform">
				<el-select v-model="refreshtime" placeholder="刷新时间" @change="reTimer">
          <el-option label="No Sec" :value="86400000"></el-option>
					<el-option label="60秒" :value="60000"></el-option>
					<el-option label="30秒" :value="30000"></el-option>
					<el-option label="10秒" :value="10000"></el-option>
				</el-select>
				<el-input v-model="searchinfo.account" placeholder="账号" label="账号" clearable></el-input>
				<el-date-picker
					v-model="searchinfo.timerange"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
					align="right" @change="dosearch()">
				</el-date-picker>
				<el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy"
						   :loading="isbusy">搜索
				</el-button>
			</div>
		</div>
		<div class="pagecontent">
			<el-table ref="multipleTable"
					  :data="optloglist"
					  @selection-change="SelectionChange"
					  tooltip-effect="dark" style="width: 100%">
				<el-table-column
					label="时间" width="150"
					prop="created_at">
				</el-table-column>
				<el-table-column
					label="账号" width="120"
          prop="account">
				</el-table-column>
        <el-table-column
            label="IP" width="120"
            prop="ip">
        </el-table-column>
        <el-table-column
            label="操作" width="200"
            prop="apiurl">
        </el-table-column>
        <el-table-column
            label="操作数据"
            prop="opdata">
        </el-table-column>
			</el-table>
			<div style="display: flex;flex-direction: row; justify-content: space-between ">
				<el-pagination
					background
					@size-change="SizeChange"
					@current-change="getoptloglist"
					:current-page="searchinfo.page"
					:page-sizes="PAGE.pageSizes"
					:page-size="searchinfo.pagesize"
					:layout="PAGE.layout"
					:total="totalRows">
				</el-pagination>
			</div>
		</div>
	</el-main>
</template>

<script>
// @ is an alias to /src

export default {
	name: 'optlog',
	components: {},
	data() {
		return {
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			searchinfo: {
				page: 1,
				pagesize: this.PAGE.pageSize,
				account: '',
				timerange: []
			},
			optloglist: [],
			totalRows: 0,
			multipleSelection: [],
			isbusy: false,

			intervalId: null,
			refreshtime: 60000,
		}
	},
	methods: {
		SizeChange(val) {
			this.searchinfo.pagesize = val;
			this.getoptloglist(1);
		},
		SelectionChange(val) {
			this.multipleSelection = val;
			console.log('this.multipleSelection', this.multipleSelection);
		},
		async dosearch() {
			this.getoptloglist(1);
		},
		async getoptloglist(page) {
			this.searchinfo.page = page || this.searchinfo.page;
      if( this.searchinfo.timerange && this.searchinfo.timerange[0]){
        this.searchinfo.start_time = this.searchinfo.timerange[0];
      } else {
        this.searchinfo.start_time = "";
      }
      if( this.searchinfo.timerange && this.searchinfo.timerange[1]){
        this.searchinfo.end_time = this.searchinfo.timerange[1];
      } else {
        this.searchinfo.end_time = "";
      }
			this.isbusy = true;
			let res = await this.httpget('/system/optlog/index', this.searchinfo);
			if (res.code === 0) {
				this.optloglist = res.data.data;
				this.totalRows = res.data.total;
				this.isbusy = false;
			} else {
				this.isbusy = false;
			}
		},
		startTimer() {
			if (this.intervalId != null) {
				return;
			}
			this.intervalId = setInterval(() => {
				console.log("刷新" + new Date());
				this.getoptloglist(1); //加载数据函数
			}, this.refreshtime);
		},
		reTimer() {
			clearInterval(this.intervalId);
			this.intervalId = null;
			this.startTimer();
		}
	},
	mounted() {
		this.getoptloglist(1);
		this.startTimer();
	},
	destroyed() {
		clearInterval(this.intervalId); //清除计时器
		this.intervalId = null; //设置为null
	}
}
</script>
